import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Layout from "../../components/layouts/gated-layout";
import Advertisement from "../../components/layouts/advertisement-column";
import Logo from "../../images/asterra-logo-color.svg";
import { PasswordResetForm } from "../../components/utilities/form";

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const PasswordColumn = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .logo {
    position: absolute;
    inset: 3rem auto auto 3rem;
  }
  .password {
    padding: 3rem;
    max-width: 75%;
    width: 75%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    .password {
      max-width: 100%;
      width: 100%;
    }
  }
`;

const Password = () => {
  return (
    <Layout>
      <Container>
        <PasswordColumn>
          <Link to="/">
            <img
              src={Logo}
              className="img-fluid logo"
              alt="ASTERRA full color logo"
            />
          </Link>
          <div className="password">
            <PasswordResetForm />
          </div>
        </PasswordColumn>
        <Advertisement />
      </Container>
    </Layout>
  );
};
export default Password;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
